import Papa from 'papaparse'

import { AgeGroupReportProps } from 'app/core/types/age-group'
import {
  AnimalCreateProps,
  AnimalCreateResponseData,
  AnimalDynamicFilter,
  AnimalFilterProps,
  AnimalGeneralReportProps,
  AnimalListProps,
  AnimalReadResponseProps,
  AnimalSexLabel,
  AnimalStreamProps,
  AnimalUpdateRequestData,
  IAnimalEntity,
} from 'app/core/types/animal'
import { BirthType } from 'app/core/types/birth'
import { ShutdownRequestProps } from 'app/core/types/shutdown'
import {
  addToast,
  removeEmptyFilters,
} from 'app/core/utils'
import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'
import { http } from 'interfaces/http'

const baseUrl = '/animals/'

const getReadAllAnimals = async (
  filters?: Partial<AnimalFilterProps>,
  page?: number,
  size?: number
): Promise<AnimalReadResponseProps> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page: page ?? 1, size },
  })
  return response.data
}

const getReadAnimalsStream = async (
  filters?: Partial<AnimalFilterProps>
): Promise<Partial<AnimalStreamProps>[]> => {
  const response = await http.get(`${baseUrl}stream`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })

  const animals: Partial<AnimalStreamProps>[] = []
  const csv = Papa.parse(response.data)
  let currentAnimalID: number | undefined = undefined

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    const isCompositeBreed = item[26] === 'True'
    if (Number(item[0]) === currentAnimalID && !isCompositeBreed) {
      const currentAnimal = animals.pop() as Partial<AnimalStreamProps>
      const animalHasSecondBreed =
        currentAnimal.breedName1 &&
        currentAnimal.breedName1 !== item[10] &&
        !currentAnimal.breedName2

      if (animalHasSecondBreed) {
        currentAnimal.breedUnified = currentAnimal.breedUnified?.concat(
          ' / ',
          `${numberWithThousandDotSeparatorFormat(Number(item[11]))}% - ${
            item[10]
          }`
        )
      }

      animals.push(currentAnimal)

      continue
    }

    currentAnimalID = Number(item[0])
    animals.push({
      id: currentAnimalID,
      birthNumber: item[1],
      stockNumber: item[2],
      electronicEartag: Number(item[3]) || undefined,
      name: item[4],
      birthDate: item[5] ? new Date(item[5]) : undefined,
      sex: item[6] as AnimalSexLabel,
      father: item[7],
      mother: item[8],
      breedName1: item[10],
      breedUnified: !isCompositeBreed
        ? `${numberWithThousandDotSeparatorFormat(Number(item[11]))}% - ${
            item[10]
          }`
        : 'Composta',
      category: item[12],
      sector: item[13],
      farm: item[14],
      weaningDate: item[15] ? new Date(item[15]) : undefined,
      weaningWeight: Number(item[16]) || undefined,
      birthForecast: item[17] ? new Date(item[17]) : undefined,
      lastGivenBirthStockNumber: item[18],
      lastGivenBirthBirthNumber: item[19],
      lastGivenBirthSex: item[20] as AnimalSexLabel,
      lastGivenBirthDate: item[21] ? new Date(item[21]) : undefined,
      lastGivenBirthType: item[22] as BirthType,
      shutdownDate: item[23] ? new Date(item[23]) : undefined,
      shutdownType: item[24],
      shutdownReason: item[25],
      series: item[27],
      breedAssociation: item[28],
      breedAssociationFeedingGroup: item[29],
      breedAssociationHandlingGroup: item[30],
    })
  }

  return animals
}

const getReadAnimal = async (
  animalId: string | number
): Promise<IAnimalEntity> => {
  const url = `${baseUrl}${animalId}/`

  const response = await http.get(url)
  return response.data
}

const getReadAnimalByCalf = async (
  filters?: AnimalDynamicFilter
): Promise<AnimalListProps> => {
  const response = await http.get(`${baseUrl}mother`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const getReadAnimalsAgeGroupReport = async (
  startDate: string,
  endDate: string
): Promise<Record<string, AgeGroupReportProps>> => {
  const response = await http.get(`${baseUrl}age-group-report`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  })

  return response.data
}

const postCreateAnimalBulk = async (
  animals: AnimalCreateProps[]
): Promise<AnimalCreateResponseData | void> => {
  try {
    const response = await http.post(`${baseUrl}bulk/`, animals)
    return response.data as AnimalCreateResponseData
  } catch (e) {
    addToast({ message: (e as Error).message })
  }
}

const postUpdateAnimalBulk = async (
  animals: Partial<AnimalCreateProps[]>
): Promise<[]> => {
  const response = await http.post(`${baseUrl}bulk_update/`, animals)
  return response.data
}

const patchUpdateAnimal = async (
  animalId: string | number,
  request: AnimalUpdateRequestData
): Promise<IAnimalEntity> => {
  const response = await http.patch(`${baseUrl}${animalId}/`, request)
  return response.data
}

const postCreateShutdown = async (
  animals: ShutdownRequestProps[]
): Promise<[]> => {
  const response = await http.post(`${baseUrl}shutdown/`, animals)
  return response.data
}

const postRevertShutdown = async (animalId: number): Promise<void> => {
  await http.post(`${baseUrl}revert-shutdown/${animalId}/`)
}

const getGeneralReport = async (
  filters?: Partial<AnimalFilterProps>
): Promise<Partial<AnimalGeneralReportProps>[]> => {
  const response = await http.get(`${baseUrl}general-report`, {
    params: { ...removeEmptyFilters(filters) },
  })

  const animals: Partial<AnimalGeneralReportProps>[] = []
  const csv = Papa.parse(response.data)
  let currentAnimalID: number | undefined = undefined

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    const isCompositeBreed = item[43] === 'True'
    if (Number(item[0]) === currentAnimalID && !isCompositeBreed) {
      const currentAnimal = animals.pop() as Partial<AnimalStreamProps>
      const animalHasSecondBreed =
        currentAnimal.breedName1 &&
        currentAnimal.breedName1 !== item[12] &&
        !currentAnimal.breedName2

      if (animalHasSecondBreed) {
        currentAnimal.breedUnified = currentAnimal.breedUnified?.concat(
          ' / ',
          `${numberWithThousandDotSeparatorFormat(Number(item[14]))}% - ${
            item[13]
          }`
        )
      }

      animals.push(currentAnimal)
      continue
    }

    currentAnimalID = Number(item[0])
    animals.push({
      id: currentAnimalID,
      birthNumber: item[1],
      stockNumber: item[2],
      electronicEartag: Number(item[3]) || undefined,
      name: item[4],
      observation: item[5] || undefined,
      observationDate: item[6] ? new Date(item[6]) : undefined,
      birthDate: item[7] ? new Date(item[7]) : undefined,
      ageGroup: item[8] || undefined,
      sex: item[9] as AnimalSexLabel,
      father: item[10],
      mother: item[11],
      breedName1: item[12],
      breedUnified: !isCompositeBreed
        ? `${numberWithThousandDotSeparatorFormat(Number(item[14]))}% - ${
            item[13]
          }`
        : 'Composta',
      category: item[15],
      sector: item[16],
      farm: item[17],
      weaningDate: item[18] ? new Date(item[18]) : undefined,
      weaningWeight: Number(item[19]) || undefined,
      lastWeightDate: item[20] ? new Date(item[20]) : undefined,
      lastWeight: Number(item[21]) || undefined,
      lifeADG: Number(item[22]) || undefined,
      lastEvaluationDate: item[23] ? new Date(item[23]) : undefined,
      lastEvaluationScore: item[24],
      lastEvaluation: item[25],
      lastRecommendationDate: item[26] ? new Date(item[26]) : undefined,
      lastRecommendationMale: item[27],
      lastInseminationDate: item[28] ? new Date(item[28]) : undefined,
      lastInseminationType: item[29],
      lastInseminationSemen: item[30],
      lastInseminationResponsible: item[31],
      lastReproductiveDiagnosticDate: item[32] ? new Date(item[32]) : undefined,
      lastReproductiveDiagnostic:
        item[33] == 'True'
          ? 'Positivo'
          : item[33] == 'False'
          ? 'Negativo'
          : undefined,
      birthForecast: item[34] ? new Date(item[34]) : undefined,
      lastGivenBirthStockNumber: item[35],
      lastGivenBirthBirthNumber: item[36],
      lastGivenBirthSex: item[37] as AnimalSexLabel,
      lastGivenBirthDate: item[38] ? new Date(item[38]) : undefined,
      lastGivenBirthType: item[39] as BirthType,
      shutdownDate: item[40] ? new Date(item[40]) : undefined,
      shutdownType: item[41],
      shutdownReason: item[42],
      series: item[44],
      breedAssociation: item[45],
      breedAssociationFeedingGroup: item[46],
      breedAssociationHandlingGroup: item[47],
    })
  }

  return animals
}

export {
  getGeneralReport,
  getReadAllAnimals,
  getReadAnimal,
  getReadAnimalByCalf,
  getReadAnimalsAgeGroupReport,
  getReadAnimalsStream,
  patchUpdateAnimal,
  postCreateAnimalBulk,
  postCreateShutdown,
  postRevertShutdown,
  postUpdateAnimalBulk,
}
