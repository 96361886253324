/* eslint-disable no-console */
import jwt_decode from 'jwt-decode'

import { StorageKeys } from '../types/storage'
import { IUserJwtPayload, UserType } from '../types/user'

export const isAuthentication = (): boolean => {
  const token = localStorage.getItem(StorageKeys.auth_token)

  if (!token) return false

  try {
    const decodedToken: IUserJwtPayload = jwt_decode(token)
    const currentDate = new Date()
    return (decodedToken.exp as number) * 1000 > currentDate.getTime()
  } catch (error) {
    return false
  }
}

export const isAdmin = (): boolean => {
  const token = localStorage.getItem(StorageKeys.auth_token)

  if (!token) {
    return false
  }

  const decodedToken: IUserJwtPayload = jwt_decode(token as string)

  if (decodedToken.user_type !== UserType.admin) {
    return false
  }

  return true
}
