/* eslint-disable @typescript-eslint/naming-convention */
import Papa from 'papaparse'

import { AnimalCowhideColors, AnimalSexLabel } from 'app/core/types/animal'
import {
  BirthCreateRequestData,
  BirthCreateResponseData,
  BirthInfoResponseData,
  BirthProps,
  BirthType,
  BirthUpdateRequestData,
  BirthsFilterProps,
  BirthsResponseData,
  BirthsStreamProps,
} from 'app/core/types/birth'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/calf_birth/'

const getReadAllBirths = async (
  filters?: BirthsFilterProps,
  page?: number,
  size?: number
): Promise<BirthsResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })

  return response.data
}

const getReadBirth = async (birthId: string | number): Promise<BirthProps> => {
  const url = `${baseUrl}${birthId}/`

  const response = await http.get(url)
  return response.data
}

const getBirthInfo = async (
  motherId: string | number
): Promise<BirthInfoResponseData> => {
  const response = await http.get(`${baseUrl}calf_birth_info/`, {
    paramsSerializer: { indexes: null },
    params: { mother_id: motherId },
  })
  return response.data
}

const postCreateBirth = async (
  payload: BirthCreateRequestData
): Promise<BirthCreateResponseData> => {
  const response = await http.post(baseUrl, payload)
  return response.data
}

const patchUpdateBirth = async (
  birthId: string | number,
  request: BirthUpdateRequestData
): Promise<void> => {
  const url = `${baseUrl}${birthId}/`

  const response = await http.patch(url, request)
  return response.data
}

const deleteCalfBirth = async (birthId: number): Promise<void> => {
  const url = `${baseUrl}${birthId}/`

  const response = await http.delete(url)
  return response.data
}

const getReadBirthsStream = async (
  filters?: BirthsFilterProps,
  page?: number,
  size?: number
): Promise<BirthsStreamProps[]> => {
  const response = await http.get(`${baseUrl}stream`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })

  const births: BirthsStreamProps[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    births.push({
      birthDate: item[1] ? new Date(item[1]) : undefined,
      birthType: item[2] as BirthType,
      motherName: item[4],
      motherElectronicEartag: item[5],
      motherStockNumber: item[6],
      calfName: item[8],
      calfBirthNumber: item[9],
      calfSex: item[10] ? item[10] as AnimalSexLabel : undefined,
      calfCowhideColor: item[11] as AnimalCowhideColors,
      calfBirthWeight: item[12] ? Number(item[12]) : undefined,
      farm: item[14],
      sector: item[16],
      calfBreed: item[17],
      calfSpecie: item[18],
      calfFather: item[21],
      calfSeries: item[22],
      breedAssociation: item[23],
      breedAssociationFeedingGroup: item[24],
      breedAssociationHandlingGroup: item[25],
    })
  }

  return births
}

export {
  deleteCalfBirth,
  getBirthInfo,
  getReadAllBirths,
  getReadBirth,
  getReadBirthsStream,
  patchUpdateBirth,
  postCreateBirth,
}
