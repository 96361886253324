import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction, useEffect } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
} from 'components/molecules'
import { CustomTable, ISelectButtonItem, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import {
  ALL_BOOLEAN_FILTER,
  ANIMAL_CATEGORY_FILTER,
  ANIMAL_SEX_FILTER,
  BIRTH_TYPES_FILTER,
  FilterSections,
  IS_ACTIVE_FILTER,
  REPRODUCTIVE_STATUS_FILTER,
  SHUTDOWN_REASON_OPTIONS_FILTER,
} from 'app/core/types/filters'
import { HandledAnimalProps, HandlingTypes } from 'app/core/types/handling'

type HandlingCreateProps = {
  table: Table<HandledAnimalProps>
  handlingType: HandlingTypes
  handlingDate: string
  setHandlingDate: Dispatch<SetStateAction<string>>
  quantity: number
  bottomLeftButtonAction: () => void
  bottomRightButtonAction: () => void
  headerSelectButtonItems: ISelectButtonItem[]
  isDrawerOpen: boolean
  filterSections: FilterSections
  setActiveFilterSection: Dispatch<SetStateAction<string[]>>
  handleChange: (section: string[] | undefined) => void
  handleDisabled: (name: string) => boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
}

const HandlingCreateTemplate: React.FC<HandlingCreateProps> = ({
  table,
  handlingType,
  handlingDate,
  setHandlingDate,
  quantity,
  bottomLeftButtonAction,
  bottomRightButtonAction,
  headerSelectButtonItems,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  setActiveFilterSection,
  filterSections,
  handleChange,
  handleDisabled,
  isLoading,
}) => {
  const { Form, reset, setFocus, formState } = useForm({ onSubmit })

  const resetFilters = (): void => {
    setActiveFilterSection([])
    reset()
  }

  /**
   * TODO: Improve this manual focus with debounce
   */
  useEffect(() => {
    if (!formState.isDirty) {
      setTimeout(() => setFocus('electronic_eartag'), 100)
    }
  }, [handleToggleDrawer, setFocus, formState])

  return (
    <BoxedLayout
      title={handlingType}
      date={handlingDate}
      setDate={setHandlingDate}
      dateLabel="Data do Manejo"
      quantity={quantity}
      bottomLeftButtonAction={bottomLeftButtonAction}
      bottomLeftButtonLabel="Adicionar animal"
      bottomLeftButtonIcon={<Icon name={IconNames['add-circle']} />}
      headerLargeButtonLabel="Revisar"
      headerLargeButtonAction={bottomRightButtonAction}
      headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
      headerSelectButtonLabel="Planilha"
      headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
      headerSelectButtonIconPosition={ButtonIconPosition.right}
      headerSelectButtonItems={headerSelectButtonItems}
      isChildrenSticky
    >
      <>
        <CustomTable
          table={table}
          disableNavigation
          isHeaderSticky
          isLoading={isLoading}
        />

        <CustomDrawer
          title="Filtrar"
          isOpen={isDrawerOpen}
          onClose={handleToggleDrawer}
          reset={resetFilters}
          scrollableDrawer
        >
          <Form>
            <DrawerTitle text="Informações gerais" />

            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Brinco Eletrônico"
                  placeholder="Pesquisar nº de brinco eletrônico"
                  name="electronic_eartag"
                  disabled={handleDisabled('electronic_eartag')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Nascimento"
                  placeholder="Pesquisar nº de nascimento"
                  name="birth_number"
                  disabled={handleDisabled('birth_number')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Plantel"
                  placeholder="Pesquisar nº de plantel"
                  name="stock_number"
                  disabled={handleDisabled('stock_number')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <Form.Select
                  label="Categoria"
                  name="categories"
                  options={ANIMAL_CATEGORY_FILTER}
                  disabled={handleDisabled('categories')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nome do Animal"
                  placeholder="Pesquisar nome"
                  name="name"
                  disabled={handleDisabled('name')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
              <DrawerCol column>
                <label htmlFor="init_date">Período de Nascimento</label>
                <div>
                  <Form.InputText
                    name="init_date"
                    htmlType="date"
                    disabled={handleDisabled('init_date')}
                    onChange={(): void =>
                      handleChange(filterSections?.generalInfoSection)
                    }
                  />

                  <Form.InputText
                    name="final_date"
                    htmlType="date"
                    disabled={handleDisabled('final_date')}
                    onChange={(): void =>
                      handleChange(filterSections?.generalInfoSection)
                    }
                  />
                </div>
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <Form.RadioButton
                  chipButtonMode
                  title="Sexo do Animal"
                  name="sex"
                  disabled={handleDisabled('sex')}
                  options={ANIMAL_SEX_FILTER}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.RadioButton
                  chipButtonMode
                  title="Status"
                  name="is_active"
                  options={IS_ACTIVE_FILTER}
                  disabled={handleDisabled('is_active')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.RadioButton
                  chipButtonMode
                  title="Animal de associação"
                  name="association_animal"
                  options={ALL_BOOLEAN_FILTER}
                  disabled={handleDisabled('association_animal')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Partos" />

            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Data do último parto"
                  name="last_birth_date_init"
                  htmlType="date"
                  disabled={handleDisabled('last_birth_date_init')}
                  onChange={(): void =>
                    handleChange(filterSections?.birthSection)
                  }
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="last_birth_date_end"
                  htmlType="date"
                  disabled={handleDisabled('last_birth_date_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.birthSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Partos previstos entre"
                  name="birth_forecast_start"
                  htmlType="date"
                  disabled={handleDisabled('birth_forecast_start')}
                  onChange={(): void =>
                    handleChange(filterSections?.birthSection)
                  }
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="birth_forecast_end"
                  htmlType="date"
                  disabled={handleDisabled('birth_forecast_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.birthSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.Select
                  label="Tipo do último parto"
                  name="last_birth_type"
                  disabled={handleDisabled('last_birth_type')}
                  options={BIRTH_TYPES_FILTER}
                  onChange={(): void =>
                    handleChange(filterSections?.birthSection)
                  }
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Baixas" />

            <DrawerRow>
              <DrawerCol>
                <Form.Select
                  label="Motivo de baixa"
                  name="shutdown_reason"
                  options={SHUTDOWN_REASON_OPTIONS_FILTER}
                  disabled={handleDisabled('shutdown_reason')}
                  onChange={(): void =>
                    handleChange(filterSections?.shutdownSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Data da baixa"
                  name="shutdown_date_init"
                  htmlType="date"
                  disabled={handleDisabled('shutdown_date_init')}
                  onChange={(): void =>
                    handleChange(filterSections?.shutdownSection)
                  }
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="shutdown_date_end"
                  htmlType="date"
                  disabled={handleDisabled('shutdown_date_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.shutdownSection)
                  }
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Reprodução" />

            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Desmamaram entre"
                  name="weaning_date_init"
                  htmlType="date"
                  disabled={handleDisabled('weaning_date_init')}
                  onChange={(): void =>
                    handleChange(filterSections?.reproductionSection)
                  }
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="weaning_date_end"
                  htmlType="date"
                  disabled={handleDisabled('weaning_date_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.reproductionSection)
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.CheckboxButton
                  emptyLabelSpacing
                  name="aptitude"
                  options={REPRODUCTIVE_STATUS_FILTER}
                  disabled={handleDisabled('aptitude')}
                  onChange={(): void =>
                    handleChange(filterSections?.reproductionSection)
                  }
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerButtonContainer>
              <Button
                type={ButtonType.primary}
                label="Aplicar"
                size={ButtonSize.large}
              />
            </DrawerButtonContainer>
          </Form>
        </CustomDrawer>
      </>
    </BoxedLayout>
  )
}

export { HandlingCreateTemplate }
