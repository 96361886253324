import { SelectedFiles } from 'use-file-picker'

import { SelectItemProps } from 'components/atoms'

import { EvaluationProps } from './evaluation'
import { ShutdownType } from './shutdown'
import { BreedProps, DefaultPagination } from './system'

export enum AnimalCategory {
  growing = 'Em crescimento',
  steer = 'Novilho',
  heifer = 'Novilha',
  breedingBull = 'Reprodutor',
  cow = 'Vaca',
  yearlingBull = 'Rufião',
}

export enum AnimalCowhideColors {
  white = 'Branco',
  gray = 'Cinza',
  light_gray = 'Cinza claro',
  dark_gray = 'Cinza escuro',
  stained = 'Manchado',
}

export enum AnimalCowReproductiveStatus {
  empty = 'Vaca vazia',
  pregnant = 'Vaca Prenha',
  inseminated = 'Vaca inseminada',
}

export const COW_REPRODUCTIVE_STATUS = Object.values(
  AnimalCowReproductiveStatus
).map(value => ({
  label: value,
  value,
}))

export const COWHIDE_COLORS = Object.values(AnimalCowhideColors).map(value => ({
  label: value,
  value,
}))

export enum AnimalSpecies {
  bovine = 'Bovino',
  equine = 'Equino',
  bubaline = 'Búfalo',
}

export enum AnimalSex {
  female = 'Fêmea',
  male = 'Macho',
}

export enum AnimalSexPlural {
  female = 'Fêmeas',
  male = 'Machos',
}

export enum AnimalSexLabel {
  female = 'female',
  male = 'male',
}

export enum AnimalSexLabelPlural {
  female = 'females',
  male = 'males',
}

export const ANIMAL_SEX_OPTIONS = Object.entries(AnimalSex).map(
  ([key, value]) => ({ label: value, value: key })
)

export const ANIMAL_SEX_OPTIONS_RADIO = Object.values(AnimalSexLabel).map(
  value => ({
    label: AnimalSex[value],
    value,
  })
)

export enum AnimalParentType {
  animal = 'Animal',
  empty = 'Empty',
  breeding_group = 'Breeding Group',
}

export enum AnimalParentTypes {
  mother_great_grandfather = 'Bisavô materno',
  mother_grandfather = 'Avô materno',
  mother = 'Mãe',
  father_great_grandfather = 'Bisavô paterno',
  father_grandfather = 'Avô paterno',
  father = 'Pai',
}

export interface IAnimalIsActive {
  status: boolean
  shutdown_reason: ShutdownType
  shutdown_date: string
  shutdown_description: string
}

export interface IAnimalCowhideColorSelect {
  cowhide_color: SelectItemProps
}

export interface IAnimalBasicInformation {
  birth_number: string
  birth_date: string
  cowhide_color: string
  breed: IAnimalBreed[]
  farm: IAnimalFarm
  sector: IAnimalSector
}

export interface IAnimalBreed {
  id: number
  description: string
  abbreviation: string
  percentage: number
}

export interface IAnimalCreateBreed {
  breed_id: number
  percentage: number
}

export interface IAnimalFarm {
  id: number
  name: string
  prefix?: string
}

export interface IAnimalSector {
  id: number
  name: string
  farm_id: number
}

export interface IAnimalBreedPercentage {
  breed: string
  percentage: number
}

export interface IAnimalParentProps {
  id?: number
  birth_number?: string | null
  stock_number?: string
  name?: string
  type?: AnimalParentType
}

export interface IGenealogyAnimalParent extends IAnimalParentProps {
  great_grandfather: IAnimalParentProps
  grandfather: IAnimalParentProps
}

export interface IGenealogyAnimalImportedParent {
  imported_father: string | null
  imported_mother: string | null
  imported_paternal_grandfather: string | null
  imported_maternal_grandfather: string | null
  imported_paternal_great_grandfather: string | null
  imported_maternal_great_grandfather: string | null
}

export interface IAnimalBreedingGroupFather {
  id: number
  name: string
}

export interface IAnimalMale {
  aptitude: boolean
}

export interface IAnimalFemale {
  status: string
  expected_birth_date?: string
}

export interface IAnimalHeader {
  is_active: boolean
  species: string
  breed?: string
  sex: AnimalSex | AnimalSexLabel
  name: string
  category: AnimalCategory
  electronic_eartag: string
  stock_number: string
}

export interface IAnimalAdditionalInformation {
  belongs_to_herd: boolean
  birth_weight: number
  weight: number
  composite_breed: boolean
  series?: string
}

export interface IAnimalGenealogy {
  mother_info: IGenealogyAnimalParent
  father_info: IGenealogyAnimalParent
  imported_info: IGenealogyAnimalImportedParent
}

export interface IAnimalAssociation {
  handling_group_name: string
  breed_association_name: string
  feeding_group_name: string
}

export interface IAnimalReproduction {
  id: number
  aptitude: boolean
  allows_breeding: boolean
  expected_birth_date: string
  status: AnimalCowReproductiveStatus
}

export type AnimalListProps = {
  id: number
  animal_id: number
  birth_number: string
  stock_number: string
  electronic_eartag: string
  birth_date: string
  birth_weight: number
  current_weight: number
  belongs_to_herd: boolean
  belongs_to_association: boolean
  sex: AnimalSex | AnimalSexLabel
  sector_name: string
  calving_type: string
  last_calving: string
  upcoming_calving: string
  is_active: boolean
  calf_father?: string
  is_birth_valid?: boolean
  evaluations?: EvaluationProps[]
  father_name?: string
  father_stock_number?: string
  name?: string
  category: string
}

export type AnimalStreamProps = {
  id: number
  birthNumber: string
  stockNumber: string
  electronicEartag: number
  name: string
  birthDate: Date
  sex: AnimalSexLabel
  father: string
  mother: string
  breedName1: string
  breedPercentage1: number
  breedName2: string
  breedPercentage2: number
  breedUnified: string
  category: string
  sector: string
  farm: string
  weaningDate: Date
  weaningWeight: number
  birthForecast: Date
  lastGivenBirthStockNumber: string
  lastGivenBirthBirthNumber: string
  lastGivenBirthSex: AnimalSexLabel
  lastGivenBirthDate: Date
  lastGivenBirthType: string
  shutdownDate: Date
  shutdownType: string
  shutdownReason: string
  series: string
  breedAssociation: string
  breedAssociationFeedingGroup: string
  breedAssociationHandlingGroup: string
}

export type AnimalGeneralReportProps = {
  observation: string
  observationDate: Date
  ageGroup: string
  lastWeightDate: Date
  lastWeight: number
  lifeADG: number
  lastEvaluationDate: Date
  lastEvaluationScore: string
  lastEvaluation: string
  lastRecommendationDate: Date
  lastRecommendationMale: string
  lastInseminationDate: Date
  lastInseminationType: string
  lastInseminationSemen: string
  lastInseminationResponsible: string
  lastReproductiveDiagnosticDate: Date
  lastReproductiveDiagnostic: string
} & AnimalStreamProps

export type AnimalReadResponseProps = {
  items: AnimalListProps[]
} & DefaultPagination

export enum AnimalHistoryTypes {
  move = 'move',
  animal_entity = 'animal_entity',
  first_handling = 'first_handling',
  fourth_month_handling = 'fourth_month_handling',
  weaning_handling = 'weaning_handling',
  fattening_handling = 'fattening_handling',
  sanitary_handling = 'sanitary_handling',
  breeding_station = 'breeding_station',
  breeding_group = 'breeding_group',
  iatf_schedule = 'iatf_schedule',
  animal_shutdown = 'animal_shutdown',
  breeding_diagnostic = 'breeding_diagnostic',
  insemination = 'insemination',
  give_birth = 'give_birth',
  be_born = 'be_born',
  delete_birth = 'delete_birth',
  delete_breeding_diagnostic = 'delete_breeding_diagnostic',
  animal_evaluation = 'animal_evaluation',
  mating_recommendation = 'mating_recommendation',
}

export enum AnimalFields {
  electronic_eartag = 'Nº do Brinco Eletrônico',
  cowhide_color = 'Pelagem',
  stock_number = 'Nº de Plantel',
  sex = 'Sexo',
}

export type AnimalHistoryProps = {
  id: number
  animal_id: number
  type: AnimalHistoryTypes
  date: string
  data: { field: string; value: number | string; is_old_value: boolean }[]
  is_update: boolean
  is_removal: boolean
}

export interface IAnimalEntity {
  id: number
  header: IAnimalHeader
  basic_information: IAnimalBasicInformation
  additional_information: IAnimalAdditionalInformation
  genealogy: IAnimalGenealogy
  association: IAnimalAssociation
  reproduction: IAnimalReproduction
  history: AnimalHistoryProps[]
}

export type AnimalCreateProps = {
  animal_id?: number
  belongs_to_association?: boolean
  belongs_to_herd?: boolean
  birth_date?: string
  birth_number?: string | null
  birth_weight?: number
  breed_association_id?: number | null
  breed_association_name?: string
  is_composite_breed?: boolean
  breeds?: IAnimalCreateBreed[]
  breed_first_id?: string | number | null
  breed_first_name?: string
  breed_first_percentage?: string
  breed_second_id?: string | number | null
  breed_second_name?: string
  breed_second_percentage?: string
  category?: AnimalCategory
  cowhide_color?: string
  current_weight?: number
  electronic_eartag?: string
  farm_id?: string | number | null
  feeding_group_id?: number | null
  handling_group_id?: number | null
  has_pedigree?: boolean
  imported_father?: string | null
  imported_mother?: string | null
  id?: number
  is_active?: boolean
  name?: string
  reproduction_id?: string | number | null
  sector_id?: string | number | null
  sector_name?: string | null
  sex?: AnimalSexLabel
  species?: AnimalSpecies
  stock_number?: string
  error_message?: string
  series?: string
  [key: string]: unknown // Index signature
}

export type AnimalRequestData = {
  init_date: string
  final_date: string
  sex: AnimalSexLabel
  breeding_station: 'true' | 'false'
  breeding_station_id: number
  breeding_group_id: number
  aptitude: 'true' | 'false'
  is_active: 'true' | 'false'
} & Pick<IAnimalHeader, 'stock_number' | 'electronic_eartag' | 'name'> &
  Pick<IAnimalCowhideColorSelect, 'cowhide_color'> &
  Pick<IAnimalBasicInformation, 'birth_number'> &
  Pick<DefaultPagination, 'page' | 'size'>

export type AnimalUpdateRequestData = {
  electronic_eartag?: string | null
  sex?: AnimalSexLabel | undefined
  stock_number?: string | undefined
  cowhide_color?: string | undefined
}

export type AnimalDynamicFilter = {
  stock_number_or_birth_number_or_electronic_eartag: string
}

export type AnimalRecommendationFilter = {
  like_stock_number_or_birth_number_or_electronic_eartag: string
}

export type AnimalFilterProps = AnimalRequestData &
  AnimalDynamicFilter &
  AnimalRecommendationFilter & {
    belongs_to_herd: 'true' | 'false'
    breeding_station_with_group: number
    mother_id: number
    load_father_name?: 'true' | 'false'
    sector_id: number[]
    load_actions?: 'true' | 'false'
  }

export type AnimalFailedResponseData = {
  animal: AnimalCreateProps
  error_message: string
}

export type AnimalCreateResponseData = {
  created_animals?: AnimalCreateProps[]
  failed_animals?: AnimalFailedResponseData[]
}

export type ImportAnimalProps = {
  sectorId: number
  file: SelectedFiles
  breeds: BreedProps[]
}

export class ImportAnimalError extends Error {
  data: Blob

  constructor(data: Blob) {
    super()
    this.data = data
  }
}

export type ElectronicEartagCreateProps = {
  id?: number
  stock_number?: string
  electronic_eartag?: string
  new_electronic_eartag?: string

  error_message?: string
  [key: string]: unknown // Index signature
}

export type StockNumberCreateProps = {
  id?: number
  stock_number?: string
  electronic_eartag?: string
  new_stock_number?: string

  error_message?: string
  [key: string]: unknown // Index signature
}

export type ElectronicEartagAnimalsResponseData = {
  items: ElectronicEartagCreateProps[]
} & DefaultPagination

export type StockNumberAnimalsResponseData = {
  items: StockNumberCreateProps[]
} & DefaultPagination

export type ImportElectronicEartagProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  new_electronic_eartag: string
  [index: number]: string
}

export type ImportStockNumberProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  new_stock_number: string
  [index: number]: string
}

export type ImportedShutdownProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  weight: string
  [index: number]: string
}

export type ExportHerdDynamicRequestData = {
  startDate: string
  endDate: string
}

export enum AnimalDrawerType {
  filter = 'filter',
  include = 'include',
}
