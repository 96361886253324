/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import Switch from 'react-switch'

import { InputText, Select, SelectItemProps } from 'components/atoms'
import { InputStatus } from 'components/molecules'

import { AnimalCreateProps, COWHIDE_COLORS } from 'app/core/types/animal'
import { ANIMAL_CATEGORY, ANIMAL_SEX } from 'app/core/types/filters'
import { validateSeries } from 'app/core/utils'

import styles from './styles.module.scss'

const animalEditableColumns = (
  breedsDroplist: SelectItemProps[],
  associationsDroplist: SelectItemProps[],
  associationFeedingGroupsDroplist: SelectItemProps[],
  associationHandlingGroupsDroplist: SelectItemProps[]
): Partial<ColumnDef<AnimalCreateProps>> => ({
  cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
    const inputId = id as keyof AnimalCreateProps & string

    const initialValue = getValue()

    const [value, setValue] = useState(initialValue)
    const [error, setError] = useState<string>()

    const maxPercentageLimit = 100
    const minPercentageLimit = 1

    const dateInputArray = ['birth_date']
    const numberInputArray = [
      'birth_weight',
      'current_weight',
      'breed_first_percentage',
      'breed_second_percentage',
    ]
    const weightInputArray = ['birth_weight', 'current_weight']
    const percentageInputArray = [
      'breed_first_percentage',
      'breed_second_percentage',
    ]
    const booleanInputArray = [
      'is_composite_breed',
      'has_pedigree',
      'belongs_to_herd',
      'belongs_to_association',
    ]
    const droplistArray = [
      'sex',
      'category',
      'cowhide_color',
      'breed_first_id',
      'breed_second_id',
      'breed_association_id',
      'handling_group_id',
      'feeding_group_id',
    ]
    const pedigreeInputArray = ['imported_mother', 'imported_father']
    const associationInputArray = [
      'series',
      'breed_association_id',
      'handling_group_id',
      'feeding_group_id',
    ]
    const firstBreedInputArray = [
      'breed_first_id',
      'breed_first_name',
      'breed_first_percentage',
    ]
    const secondBreedInputArray = [
      'breed_second_id',
      'breed_second_name',
      'breed_second_percentage',
    ]
    const breedInputArray = firstBreedInputArray.concat(secondBreedInputArray)

    const onChange = (inputValue: string, inputId?: string): void => {
      const isBreedPercentage = inputId === 'breed_first_percentage'
      const isMaxPercentage =
        inputValue && parseFloat(inputValue) <= maxPercentageLimit
      const isMinPercentage =
        inputValue && parseFloat(inputValue) >= minPercentageLimit
      const isValidPercentage = isMaxPercentage && isMinPercentage

      if (isBreedPercentage && !isValidPercentage) {
        setValue(maxPercentageLimit.toString())
        table.options.meta?.updateData(
          index,
          'breed_second_percentage',
          maxPercentageLimit
        )
        table.options.meta?.updateData(index, 'breed_second_percentage', 0)
        return
      }

      if (isBreedPercentage && isValidPercentage) {
        const breedFirstPercentage = parseFloat(inputValue)
        const breedSecondPercentage = maxPercentageLimit - breedFirstPercentage

        table.options.meta?.updateData(
          index,
          'breed_second_percentage',
          breedSecondPercentage.toString()
        )
      }

      if (inputId === 'series') {
        const { sanitizedValue, errorMessage } = validateSeries(inputValue)
        setValue(sanitizedValue)
        setError(errorMessage)
        return
      }

      setValue(inputValue)
    }

    const onBlur = (): void => {
      table.options.meta?.updateData(index, inputId, value)
    }

    const onSelect = (selectedValue: string | boolean): void => {
      setValue(selectedValue)
      table.options.meta?.updateData(
        index,
        inputId,
        Number(selectedValue) || selectedValue
      )
      if (inputId === 'is_composite_breed' && selectedValue) {
        breedInputArray.forEach(_inputId =>
          table.options.meta?.updateData(index, _inputId, undefined)
        )
      }

      if (inputId === 'is_composite_breed' && !selectedValue) {
        table.options.meta?.updateData(
          index,
          'breed_first_percentage',
          maxPercentageLimit.toString()
        )
      }

      if (inputId === 'belongs_to_association') {
        table.options.meta?.updateData(
          index,
          'breed_association_id',
          selectedValue ? associationsDroplist[0].value : undefined
        )
        table.options.meta?.updateData(
          index,
          'handling_group_id',
          selectedValue ? associationHandlingGroupsDroplist[0].value : undefined
        )
        table.options.meta?.updateData(
          index,
          'feeding_group_id',
          selectedValue ? associationFeedingGroupsDroplist[0].value : undefined
        )

        if (!selectedValue) {
          table.options.meta?.updateData(index, 'series', undefined)
          setError('')
        }
      }
    }

    const saveSelectedLabel = (selectedLabel: string): void => {
      if (selectedLabel && inputId === 'breed_first_id') {
        table.options.meta?.updateData(index, 'breed_first_name', selectedLabel)
      }

      if (selectedLabel && inputId === 'breed_second_id') {
        table.options.meta?.updateData(
          index,
          'breed_second_name',
          selectedLabel
        )
      }

      if (selectedLabel && inputId === 'breed_association_id') {
        table.options.meta?.updateData(
          index,
          'breed_association_name',
          selectedLabel
        )
      }
    }

    useEffect(() => {
      if (inputId === 'series') {
        const { sanitizedValue, errorMessage } = validateSeries(
          initialValue as string,
          true
        )
        setValue(sanitizedValue)
        setError(errorMessage)
        return
      }

      setValue(initialValue)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue])

    const disableInputChecker = (selectedInput: string): boolean => {
      const hasPedigree = original.has_pedigree
      const hasAssociation = original.belongs_to_association
      const isCompositeBreed = original.is_composite_breed
      const isBreedPercentMax =
        parseInt(original.breed_first_percentage as string) <
          maxPercentageLimit && original.breed_first_percentage !== '0'

      switch (selectedInput) {
        case pedigreeInputArray.find(e => e === selectedInput):
          return !hasPedigree
        case associationInputArray.find(e => e === selectedInput):
          return !hasAssociation
        case secondBreedInputArray.find(e => e === selectedInput):
          return !isBreedPercentMax || !!isCompositeBreed
        case firstBreedInputArray.find(e => e === selectedInput):
          return !!isCompositeBreed

        default:
          return false
      }
    }

    const DROPLIST_OPTIONS: Partial<
      Record<keyof AnimalCreateProps, SelectItemProps[]>
    > = {
      sex: ANIMAL_SEX,
      category: ANIMAL_CATEGORY,
      cowhide_color: COWHIDE_COLORS,
      breed_first_id: breedsDroplist,
      breed_second_id: breedsDroplist,
      breed_association_id: associationsDroplist,
      feeding_group_id: associationFeedingGroupsDroplist,
      handling_group_id: associationHandlingGroupsDroplist,
    }

    const inputType = dateInputArray.includes(inputId)
      ? 'date'
      : numberInputArray.includes(inputId)
      ? 'number'
      : undefined

    if (booleanInputArray.includes(inputId)) {
      return (
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Switch
            checked={!!value}
            onChange={onSelect}
            onColor="#C6DEC5"
            offColor="#C2C2C2"
            onHandleColor="#6A9068"
            offHandleColor="#606062"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
          <span>{value ? 'Sim' : 'Não'}</span>
        </label>
      )
    }

    if (breedInputArray.includes(inputId) && original.is_composite_breed) {
      return <span>-</span>
    }

    if (
      breedsDroplist &&
      associationsDroplist &&
      droplistArray.includes(inputId)
    ) {
      return (
        <Select
          name={inputId}
          placeholder={disableInputChecker(inputId) ? 'N/A' : 'Selecione'}
          disabled={disableInputChecker(inputId)}
          centerText
          options={DROPLIST_OPTIONS[inputId] as SelectItemProps[]}
          onChange={(value): void => {
            onSelect((value as SelectItemProps).value)
            saveSelectedLabel((value as SelectItemProps).label)
          }}
          defaultValue={DROPLIST_OPTIONS[inputId]?.find(
            e => e.value == initialValue
          )}
        />
      )
    }

    if (inputId === 'series') {
      return (
        <span className={styles.input}>
          <div className={error ? styles.seriesInputError : ''}>
            <InputText
              name={inputId}
              onChange={(e): void => onChange(e.target.value, inputId)}
              defaultValue={value as string}
              value={value as string | undefined}
              onBlur={onBlur}
              status={error ? InputStatus.error : InputStatus.default}
              className={styles.tableInput}
              disabled={disableInputChecker(inputId)}
              placeholder={disableInputChecker(inputId) ? 'N/A' : 'Insira aqui'}
              htmlType={inputType}
            />
            {error && <span className={styles.error}>{error}</span>}
          </div>
        </span>
      )
    }

    return (
      <span className={styles.input}>
        <InputText
          name={inputId}
          onChange={(e): void => onChange(e.target.value)}
          defaultValue={value as string}
          onBlur={onBlur}
          max={
            percentageInputArray.includes(inputId)
              ? maxPercentageLimit
              : undefined
          }
          min={
            percentageInputArray.includes(inputId)
              ? minPercentageLimit
              : undefined
          }
          className={styles.tableInput}
          disabled={disableInputChecker(inputId)}
          placeholder={disableInputChecker(inputId) ? 'N/A' : 'Insira aqui'}
          htmlType={inputType}
        />
        {weightInputArray.includes(inputId) && ' kg'}
        {percentageInputArray.includes(inputId) && ' %'}
      </span>
    )
  },
})

export { animalEditableColumns }
