import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useHandlings } from 'app/core/hooks/handling'
import { useProducts } from 'app/core/hooks/products'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalFilterProps } from 'app/core/types/animal'
import { FilterSections } from 'app/core/types/filters'
import {
  HandledAnimalProps,
  HandlingEntity,
  HandlingParams,
  HandlingTypes,
} from 'app/core/types/handling'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  downloadFile,
  generateXlsxTemplate,
  handledAnimalsEmptyFields,
  sanitizeString,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { handlingCreateColumns, handlingEditableColumns } from '../tables'
import { hasFilledAnimal } from './helpers'
import { HandlingCreateTemplate } from './template'

const HandlingCreate: React.FC = () => {
  const location = useLocation()

  const history = useHistory<NavigateList>()

  const {
    handledAnimals,
    setHandledAnimals,
    handlingDate,
    setHandlingDate,
    handlingType,
    filterDuplicatedAnimals,
    searchAnimals,
    savedHandlingFarm,
    savedHandlingSector,
    xlsxToHandledAnimals,
    isLoading,
  } = useHandlings(location.state as HandlingParams)
  const { productsDroplist } = useProducts()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const { id: farmId } = savedHandlingFarm
  const { id: sectorId } = savedHandlingSector

  if (!location.state || !savedHandlingFarm) {
    history.push(NavigateList.animalList)
  }

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file, handlingType),
  })

  const handlingForReview = useMemo<Omit<HandlingEntity, 'id'>>(
    () => ({
      type: handlingType,
      date: handlingDate,
      farm_id: farmId,
      handlings: handledAnimals,
    }),
    [handlingType, handlingDate, handledAnimals, farmId]
  )

  const handleAddTableRows = useCallback((): void => {
    handleToggleDrawer()
  }, [])

  const handleGoToReview = (): void => {
    if (!handlingForReview.handlings) {
      return
    }

    const invalidAnimals = handledAnimalsEmptyFields(
      handlingForReview.handlings,
      [
        'id',
        ...(![
          HandlingTypes.first_handling,
          HandlingTypes.sanitary_handling,
        ].includes(handlingType)
          ? ['current_weight']
          : []),
      ]
    )

    if (!invalidAnimals.length) {
      setHandledAnimals(prevRows => {
        return prevRows.map(row => {
          return {
            ...row,
            error_message: undefined,
          }
        })
      })

      history.push(NavigateList.handlingReview)
    } else {
      invalidAnimals.forEach(invalidAnimal => {
        setHandledAnimals(prevRows => {
          return prevRows.map(row => {
            if (row.id === invalidAnimal) {
              return {
                ...row,
                error_message: Messages.REQUIRED_FIELDS,
              }
            }
            return row
          })
        })
      })

      addToast({ message: Messages.REQUIRED_FIELDS })
    }
  }

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const updatedFilters = {
        ...filters,
        sector_id: [sectorId],
      } as AnimalFilterProps

      const animals = await searchAnimals(updatedFilters)

      const filteredAnimals = filterDuplicatedAnimals(animals, handledAnimals)

      hasFilledAnimal(handledAnimals)
        ? setHandledAnimals(prevRows => [...prevRows, ...filteredAnimals])
        : setHandledAnimals(filteredAnimals)

      handleToggleDrawer()
    },
    [
      filterDuplicatedAnimals,
      searchAnimals,
      sectorId,
      handledAnimals,
      setHandledAnimals,
    ]
  )

  const handleImportSheet = useCallback(
    async (selectedFile: SelectedFiles, type: HandlingTypes): Promise<void> => {
      try {
        const importedHandlings = await xlsxToHandledAnimals(
          selectedFile.filesContent[0].content,
          type
        )

        const filteredAnimals = filterDuplicatedAnimals(
          importedHandlings,
          handledAnimals
        )

        hasFilledAnimal(handledAnimals)
          ? setHandledAnimals(prevRows => [...prevRows, ...filteredAnimals])
          : setHandledAnimals(filteredAnimals)

        addToast({
          message: Messages.HANDLING_IMPORT_SUCCESSFUL,
          type: 'success',
        })
      } catch (e) {
        addToast({ message: Messages.ERROR_MESSAGE })
      }
    },
    [
      filterDuplicatedAnimals,
      handledAnimals,
      setHandledAnimals,
      xlsxToHandledAnimals,
    ]
  )

  const columns = useMemo<ColumnDef<HandledAnimalProps>[]>(
    () =>
      handlingCreateColumns({
        handlingType,
        handledAnimals,
        setHandledAnimals,
      }),
    [handledAnimals, handlingType, setHandledAnimals]
  )

  const table = useReactTable({
    data: handledAnimals || [],
    columns,
    defaultColumn: handlingEditableColumns(
      handlingType,
      handlingDate,
      productsDroplist || []
    ),
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        animal_id: false,
        birth_date: false,
        birth_weight: false,
      },
    },
    meta: {
      updateData: useCallback(
        (rowIndex: number, columnId: string, value: unknown) => {
          setHandledAnimals(old =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                }
              }
              return row
            })
          )
        },
        [setHandledAnimals]
      ),
    },
  })

  const filterSections: FilterSections = {
    generalInfoSection: [
      'categories',
      'sex',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
      'is_active',
      'association_animal',
    ],
    birthSection: [
      'last_birth_date_init',
      'last_birth_date_end',
      'birth_forecast_start',
      'birth_forecast_end',
      'last_birth_type',
    ],
    shutdownSection: [
      'shutdown_reason',
      'shutdown_date_init',
      'shutdown_date_end',
    ],
    reproductionSection: ['weaning_date_init', 'weaning_date_end', 'aptitude'],
  }

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const handleChange = useCallback(
    (section: string[] | undefined): void => {
      if (section) {
        setActiveFilterSection(section)
      }
    },
    [setActiveFilterSection]
  )

  useEffect(() => {
    localStorage.setItem(
      StorageKeys.handling_review,
      JSON.stringify(handlingForReview)
    )
  }, [handlingForReview])

  useEffect(() => {
    const handleAddAnimalShortcut = (event: KeyboardEvent): void => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'a') {
        event.preventDefault()
        handleAddTableRows()
      }
    }

    document.addEventListener('keydown', handleAddAnimalShortcut)

    return (): void => {
      document.removeEventListener('keydown', handleAddAnimalShortcut)
    }
  }, [handleAddTableRows])

  if (!productsDroplist?.length) {
    return <></>
  }

  const handlingImportTemplate = [
    [
      'Nº de Plantel',
      'Nº de Brinco Eletrônico',
      'Nº de Nascimento',
      'Peso',
      'Produto',
      'Dose aplicada',
      'Quantidade',
    ],
    ['', '', '', '100', '', 'ml|doses(s)', ''],
  ]

  const firstHandlingImportTemplate = [
    [
      'Nº de Plantel',
      'Nº de Nascimento',
      'Peso',
      'Cadastro de Brinco Eletrônico',
      'Produto',
      'Dose aplicada',
      'Quantidade',
    ],
    ['', '', '100', '', '', 'ml|doses(s)', ''],
  ]

  const weaningHandlingImportTemplate = [
    [
      'Nº de Plantel',
      'Nº de Brinco Eletrônico',
      'Nº de Nascimento',
      'Série',
      'Peso',
      'Produto',
      'Dose aplicada',
      'Quantidade',
    ],
    ['', '', '', '', '100', '', 'ml|doses(s)', ''],
  ]

  const getHandlingTemplate = (): string[][] => {
    switch (handlingType) {
      case HandlingTypes.first_handling:
        return firstHandlingImportTemplate
      case HandlingTypes.weaning_handling:
        return weaningHandlingImportTemplate
      default:
        return handlingImportTemplate
    }
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate(getHandlingTemplate())

        downloadFile({
          data: templateFile,
          fileName: `template-${sanitizeString(handlingType)}`,
        })
      },
    },
  ]

  return (
    <HandlingCreateTemplate
      table={table}
      handlingType={handlingType}
      handlingDate={handlingDate}
      setHandlingDate={setHandlingDate}
      quantity={handledAnimals.length}
      bottomLeftButtonAction={handleAddTableRows}
      bottomRightButtonAction={handleGoToReview}
      headerSelectButtonItems={selectButtonItems}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterAnimal}
      setActiveFilterSection={setActiveFilterSection}
      handleChange={handleChange}
      handleDisabled={handleDisabled}
      filterSections={filterSections}
      isLoading={isLoading}
    />
  )
}

export { HandlingCreate }
