import { ColumnDef } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import {
  cellEmptyState,
  handleDeleteRow,
} from 'components/organisms/custom-table/helpers'

import { BirthCreateProps } from 'app/core/types/birth'

import styles from './styles.module.scss'

type AnimalColumnsProps = {
  animals: BirthCreateProps[]
  setAnimals: Dispatch<SetStateAction<BirthCreateProps[]>>
}

const birthCreateColumns = ({
  animals,
  setAnimals,
}: AnimalColumnsProps): ColumnDef<BirthCreateProps, unknown>[] => [
  {
    header: 'Registro de parto',
    meta: {
      colSpan: 5,
      mainHeader: true,
    },
    columns: [
      {
        header: '#',
        accessorKey: 'id',
        cell: (info): JSX.Element => (
          <span className={styles.orderCell}>{info.row.index + 1}</span>
        ),
      },
      {
        header: 'Data do parto',
        accessorKey: 'date',
      },
      {
        header: 'Nº de Plantel',
        accessorKey: 'mother_stock_number',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Nº de Brinco Eletrônico',
        accessorKey: 'mother_electronic_eartag',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Tipo de parto',
        accessorKey: 'type',
      },
    ],
  },
  {
    header: 'Registro de cria',
    meta: {
      colSpan: 7,
      mainHeader: true,
    },
    columns: [
      {
        header: 'Nº de Nascimento',
        accessorKey: 'birth_number',
      },
      {
        header: 'Nome da cria',
        accessorKey: 'name',
      },
      {
        header: 'Sexo',
        accessorKey: 'sex',
      },
      {
        header: 'Cor/Pelagem',
        accessorKey: 'cowhide_color',
      },
      {
        header: 'Peso de nascimento',
        accessorKey: 'birth_weight',
      },
      {
        header: 'Série',
        accessorKey: 'series',
      },
      {
        header: 'Pai/Grupo de repasse',
        accessorKey: 'father_name',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        id: 'delete',
        cell: (info): JSX.Element => (
          <Icon
            name={IconNames.close}
            onClick={(): void =>
              handleDeleteRow(animals, setAnimals, info.row.index)
            }
          />
        ),
      },
    ],
  },
]

export { birthCreateColumns }
