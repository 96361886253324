import {
  AnimalCategory,
  AnimalCowReproductiveStatus,
  AnimalSex,
} from 'app/core/types/animal'
import { ShutdownType } from 'app/core/types/shutdown'

import { BirthType } from './birth'

export type FilterSections = {
  generalInfoSection?: string[]
  additionalInfoSection?: string[]
  birthSection?: string[]
  shutdownSection?: string[]
  reproductionSection?: string[]
}

export const ANIMAL_SEX = Object.entries(AnimalSex).map(([key, value]) => ({
  label: value,
  value: key,
}))

export const ANIMAL_SEX_FILTER = [
  {
    label: 'Todos',
    value: '',
  },
  ...Object.entries(AnimalSex).map(([key, value]) => ({
    label: value,
    value: key,
  })),
]

export const ANIMAL_CATEGORY = Object.values(AnimalCategory).map(value => ({
  label: value,
  value,
}))

export const ANIMAL_CATEGORY_FILTER = Object.values(AnimalCategory).map(
  value => ({
    label: value,
    value,
  })
)

export const FEMALE_REPRODUCTIVE_STATUS_FILTER = Object.values(
  AnimalCowReproductiveStatus
).map(value => ({
  label: value,
  value,
}))

export const IS_ACTIVE_FILTER = [
  {
    label: 'Todos',
    value: '',
  },
  {
    label: 'Ativo',
    value: 'true',
  },
  {
    label: 'Inativo',
    value: 'false',
  },
]

export const IS_HERD = [
  {
    label: 'Sim',
    value: 'true',
  },
  {
    label: 'Não',
    value: 'false',
  },
]

export const SHUTDOWN_REASON_OPTIONS = Object.entries(ShutdownType).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const SHUTDOWN_REASON_OPTIONS_FILTER = Object.values(ShutdownType).map(
  value => ({
    label: value,
    value,
  })
)

export const BIRTH_TYPES_FILTER = Object.values(BirthType).map(value => ({
  label: value,
  value,
}))

export const REPRODUCTIVE_STATUS_FILTER = [
  {
    label: 'Animais aptos para reprodução',
    value: 'true',
  },
]

export const ALL_BOOLEAN_FILTER = [
  {
    label: 'Todos',
    value: '',
  },
  {
    label: 'Sim',
    value: 'true',
  },
  {
    label: 'Não',
    value: 'false',
  },
]
