/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'

import classNames from 'classnames'
import { isAfter, parse } from 'date-fns'

import { InputText, Select, SelectItemProps } from 'components/atoms'
import { InputStatus } from 'components/molecules'

import { useReproductionParams } from 'app/core/hooks'
import { AnimalSexLabel, COWHIDE_COLORS } from 'app/core/types/animal'
import {
  ABORTION_TYPES,
  BIRTH_TYPES,
  BirthCreateProps,
} from 'app/core/types/birth'
import { ANIMAL_SEX } from 'app/core/types/filters'
import { addToast, calcMaxBirthDate, validateSeries } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

const birthEditableColumns: Partial<ColumnDef<BirthCreateProps>> = {
  cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
    const inputId = id as keyof BirthCreateProps & string

    const inputDateFormat = 'yyyy-MM-dd'

    const { params } = useReproductionParams()

    const initialValue = getValue()

    const [value, setValue] = useState(initialValue)
    const [error, setError] = useState<string>()

    const dateInputArray = ['date']
    const numberInputArray = [
      'mother_stock_number',
      'mother_electronic_eartag',
      'birth_weight',
    ]
    const weightInputArray = ['birth_weight']
    const droplistArray = ['sex', 'cowhide_color', 'handling_group_id', 'type']
    const calfInputArray = [
      'birth_number',
      'name',
      'sex',
      'cowhide_color',
      'birth_weight',
      'series',
    ]

    const onChange = (inputValue: string, inputId?: string): void => {
      if (inputId === 'date') {
        const selectedDate = parse(inputValue, inputDateFormat, new Date())
        const maxDate = parse(maxBirthDate() || '', inputDateFormat, new Date())

        if (isAfter(selectedDate, maxDate)) {
          addToast({
            message: Messages.BIRTHS_MAX_DATE_INVALID,
          })

          return
        }
      }

      if (inputId === 'series') {
        const { sanitizedValue, errorMessage } = validateSeries(inputValue)
        setValue(sanitizedValue)
        setError(errorMessage)
        return
      }

      setValue(inputValue)
    }

    const onBlur = (): void => {
      table.options.meta?.updateData(index, inputId, value)
    }

    const onSelect = (selectedValue: string | boolean): void => {
      setValue(selectedValue)

      table.options.meta?.updateData(
        index,
        inputId,
        Number(selectedValue) || selectedValue
      )

      if (inputId === 'sex') {
        const defaultWeight =
          selectedValue === AnimalSexLabel.female
            ? params?.female_birth_weight
            : params?.male_birth_weight

        table.options.meta?.updateData(index, 'birth_weight', defaultWeight)
      }
    }

    useEffect(() => {
      if (inputId === 'series') {
        const { sanitizedValue, errorMessage } = validateSeries(
          initialValue as string
        )
        setValue(sanitizedValue)
        setError(errorMessage)
        return
      }

      setValue(initialValue)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue])

    const disableInputChecker = (selectedInput: string): boolean => {
      const isAbortion = original.type && ABORTION_TYPES.includes(original.type)

      switch (selectedInput) {
        case calfInputArray.find(e => e === selectedInput):
          return !!isAbortion

        default:
          return false
      }
    }

    const maxBirthDate = (): string | undefined => {
      return calcMaxBirthDate(
        String(
          original.insemination_date
            ? original.insemination_date
            : original.last_diagnostic_date
        ),
        params?.max_gestation_period
      )
    }

    const DROPLIST_OPTIONS: Partial<
      Record<keyof BirthCreateProps, SelectItemProps[]>
    > = {
      sex: ANIMAL_SEX,
      cowhide_color: COWHIDE_COLORS,
      type: BIRTH_TYPES,
    }

    const inputType = dateInputArray.includes(inputId)
      ? 'date'
      : numberInputArray.includes(inputId)
      ? 'number'
      : undefined

    if (droplistArray.includes(inputId)) {
      return (
        <Select
          name={inputId}
          placeholder={disableInputChecker(inputId) ? 'N/A' : 'Selecione'}
          disabled={disableInputChecker(inputId)}
          centerText
          options={DROPLIST_OPTIONS[inputId] as SelectItemProps[]}
          onChange={(value): void => {
            onSelect((value as SelectItemProps).value)
          }}
          defaultValue={DROPLIST_OPTIONS[inputId]?.find(
            e => e.value == initialValue
          )}
        />
      )
    }

    if (dateInputArray.includes(inputId)) {
      return (
        <span className={styles.input}>
          <InputText
            name={inputId}
            onChange={(e): void => onChange(e.target.value, inputId)}
            defaultValue={(value as string) || undefined}
            value={value as string | undefined}
            onBlur={onBlur}
            className={styles.tableInput}
            disabled={disableInputChecker(inputId)}
            htmlType="date"
            max={maxBirthDate()}
          />
        </span>
      )
    }

    return (
      <span
        className={classNames(
          styles.input,
          inputId === 'series' && styles.seriesInput
        )}
      >
        <InputText
          name={inputId}
          onChange={(e): void => onChange(e.target.value, inputId)}
          defaultValue={(value as string) || undefined}
          value={value as string | undefined}
          onBlur={onBlur}
          className={styles.tableInput}
          disabled={disableInputChecker(inputId)}
          placeholder={disableInputChecker(inputId) ? 'N/A' : 'Insira aqui'}
          htmlType={inputType}
          status={error ? InputStatus.error : InputStatus.default}
        />
        {weightInputArray.includes(inputId) && ' kg'}
        {error && <span className={styles.errorMessage}>{error}</span>}
      </span>
    )
  },
}

export { birthEditableColumns }
