import { useCallback } from 'react'

import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { FormType, IFormProps } from 'components/organisms/form/types'

import { useSectionDrawer } from 'app/core/hooks/section-drawer'
import {
  ALL_BOOLEAN_FILTER,
  ANIMAL_CATEGORY_FILTER,
  ANIMAL_SEX_FILTER,
  BIRTH_TYPES_FILTER,
  FilterSections,
  IS_ACTIVE_FILTER,
  REPRODUCTIVE_STATUS_FILTER,
  SHUTDOWN_REASON_OPTIONS_FILTER,
} from 'app/core/types/filters'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

type AnimalListDrawerProps = {
  isOpen: boolean
  onToggle: () => void
  onReset?: () => void
  onSubmit: IFormProps['onSubmit']
  farms: SelectItemProps[]
  sectors: SelectItemProps[]
}

type FilterDrawerProps = {
  isDrawerOpen: boolean
  onClose: () => void
  onReset: () => void
  form: FormType
  handleDisabled: (name: string) => boolean | undefined
  handleChange: (
    field: string,
    value: string | undefined,
    section: string[] | undefined
  ) => void
  filterSections: FilterSections
  farms: SelectItemProps[]
  sectors: SelectItemProps[]
}

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
  last_birth_date_init: yup.string(),
  last_birth_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { last_birth_date_init } = this.parent
        return isEndDateBeforeStartDate(last_birth_date_init, value)
      }
    ),
  birth_forecast_start: yup.string(),
  birth_forecast_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { birth_forecast_start } = this.parent
        return isEndDateBeforeStartDate(birth_forecast_start, value)
      }
    ),
  shutdown_date_init: yup.string(),
  shutdown_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { shutdown_date_init } = this.parent
        return isEndDateBeforeStartDate(shutdown_date_init, value)
      }
    ),
  weaning_date_init: yup.string(),
  weaning_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { weaning_date_init } = this.parent
        return isEndDateBeforeStartDate(weaning_date_init, value) || !value
      }
    ),
})

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  isDrawerOpen,
  onClose,
  onReset,
  form: FilterForm,
  handleDisabled,
  handleChange,
  filterSections: {
    generalInfoSection,
    birthSection,
    shutdownSection,
    reproductionSection,
  },
  farms: farmsDroplist,
  sectors: sectorsDroplist,
}) => {
  return (
    <CustomDrawer
      title="Filtrar"
      isOpen={isDrawerOpen}
      onClose={onClose}
      reset={onReset}
      scrollableDrawer
    >
      <FilterForm>
        <DrawerTitle text="Informações gerais" />

        <DrawerRow>
          <DrawerCol>
            <FilterForm.InputText
              label="Nº de Brinco Eletrônico"
              placeholder="Pesquisar nº de brinco eletrônico"
              name="electronic_eartag"
              disabled={handleDisabled('electronic_eartag')}
              onChange={(event): void =>
                handleChange(
                  'electronic_eartag',
                  event.target.value,
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.InputText
              label="Nº de Nascimento"
              placeholder="Pesquisar nº de nascimento"
              name="birth_number"
              disabled={handleDisabled('birth_number')}
              onChange={(event): void =>
                handleChange(
                  'birth_number',
                  event.target.value,
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.InputText
              label="Nº de Plantel"
              placeholder="Pesquisar nº de plantel"
              name="stock_number"
              disabled={handleDisabled('stock_number')}
              onChange={(event): void =>
                handleChange(
                  'stock_number',
                  event.target.value,
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>

        <DrawerRow>
          <DrawerCol>
            <FilterForm.Select
              isClearable
              label="Fazenda"
              name="farm_id"
              options={farmsDroplist}
              disabled={handleDisabled('farm_id')}
              onChange={(option): void =>
                handleChange(
                  'farm_id',
                  option ? (option as SelectItemProps).value : '',
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
          {sectorsDroplist && (
            <DrawerCol>
              <FilterForm.Select
                isClearable
                label="Setor"
                name="sector_id"
                options={sectorsDroplist}
                disabled={handleDisabled('sector_id')}
                onChange={(option): void =>
                  handleChange(
                    'sector_id',
                    option ? (option as SelectItemProps).value : '',
                    generalInfoSection
                  )
                }
              />
            </DrawerCol>
          )}
          <DrawerCol>
            <FilterForm.RadioButton
              chipButtonMode
              title="Sexo do Animal"
              name="sex"
              disabled={handleDisabled('sex')}
              options={ANIMAL_SEX_FILTER}
              onChange={(event): void =>
                handleChange('sex', event.target.value, generalInfoSection)
              }
            />
          </DrawerCol>
        </DrawerRow>

        <DrawerRow>
          <DrawerCol>
            <FilterForm.InputText
              label="Nome do Animal"
              placeholder="Pesquisar nome"
              name="name"
              disabled={handleDisabled('name')}
              onChange={(event): void =>
                handleChange('name', event.target.value, generalInfoSection)
              }
            />
          </DrawerCol>
          <DrawerCol column>
            <label htmlFor="init_date">Período de Nascimento</label>
            <div>
              <FilterForm.InputText
                name="init_date"
                htmlType="date"
                disabled={handleDisabled('init_date')}
                onChange={(event): void =>
                  handleChange(
                    'init_date',
                    event.target.value,
                    generalInfoSection
                  )
                }
              />

              <FilterForm.InputText
                name="final_date"
                htmlType="date"
                disabled={handleDisabled('final_date')}
                onChange={(event): void =>
                  handleChange(
                    'final_date',
                    event.target.value,
                    generalInfoSection
                  )
                }
              />
            </div>
          </DrawerCol>
          <DrawerCol>
            <FilterForm.RadioButton
              chipButtonMode
              title="Status"
              name="is_active"
              options={IS_ACTIVE_FILTER}
              disabled={handleDisabled('is_active')}
              onChange={(event): void =>
                handleChange(
                  'is_active',
                  event.target.value,
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>

        <DrawerRow>
          <DrawerCol>
            <FilterForm.Select
              isClearable
              label="Categoria"
              name="categories"
              options={ANIMAL_CATEGORY_FILTER}
              disabled={handleDisabled('categories')}
              onChange={(option): void =>
                handleChange(
                  'categories',
                  option ? (option as SelectItemProps).value : '',
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.RadioButton
              chipButtonMode
              title="Animal de rebanho"
              name="belongs_to_herd"
              options={ALL_BOOLEAN_FILTER}
              disabled={handleDisabled('belongs_to_herd')}
              onChange={(event): void =>
                handleChange(
                  'belongs_to_herd',
                  event.target.value,
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.RadioButton
              chipButtonMode
              title="Animal de associação"
              name="association_animal"
              options={ALL_BOOLEAN_FILTER}
              disabled={handleDisabled('association_animal')}
              onChange={(event): void =>
                handleChange(
                  'association_animal',
                  event.target.value,
                  generalInfoSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>

        <DrawerTitle text="Partos" />

        <DrawerRow>
          <DrawerCol>
            <FilterForm.InputText
              label="Data do último parto"
              name="last_birth_date_init"
              htmlType="date"
              disabled={handleDisabled('last_birth_date_init')}
              onChange={(event): void =>
                handleChange(
                  'last_birth_date_init',
                  event.target.value,
                  birthSection
                )
              }
            />

            <FilterForm.InputText
              emptyLabelSpacing
              name="last_birth_date_end"
              htmlType="date"
              disabled={handleDisabled('last_birth_date_end')}
              onChange={(event): void =>
                handleChange(
                  'last_birth_date_end',
                  event.target.value,
                  birthSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.InputText
              label="Partos previstos entre"
              name="birth_forecast_start"
              htmlType="date"
              disabled={handleDisabled('birth_forecast_start')}
              onChange={(event): void =>
                handleChange(
                  'birth_forecast_start',
                  event.target.value,
                  birthSection
                )
              }
            />

            <FilterForm.InputText
              emptyLabelSpacing
              name="birth_forecast_end"
              htmlType="date"
              disabled={handleDisabled('birth_forecast_end')}
              onChange={(event): void =>
                handleChange(
                  'birth_forecast_end',
                  event.target.value,
                  birthSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.Select
              isClearable
              label="Tipo do último parto"
              name="last_birth_type"
              disabled={handleDisabled('last_birth_type')}
              options={BIRTH_TYPES_FILTER}
              onChange={(option): void =>
                handleChange(
                  'last_birth_type',
                  option ? (option as SelectItemProps).value : '',
                  birthSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>

        <DrawerTitle text="Baixas" />

        <DrawerRow>
          <DrawerCol>
            <FilterForm.Select
              isClearable
              label="Motivo de baixa"
              name="shutdown_reason"
              options={SHUTDOWN_REASON_OPTIONS_FILTER}
              disabled={handleDisabled('shutdown_reason')}
              onChange={(option): void =>
                handleChange(
                  'shutdown_reason',
                  option ? (option as SelectItemProps).value : '',
                  shutdownSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.InputText
              label="Data da baixa"
              name="shutdown_date_init"
              htmlType="date"
              disabled={handleDisabled('shutdown_date_init')}
              onChange={(event): void =>
                handleChange(
                  'shutdown_date_init',
                  event.target.value,
                  shutdownSection
                )
              }
            />

            <FilterForm.InputText
              emptyLabelSpacing
              name="shutdown_date_end"
              htmlType="date"
              disabled={handleDisabled('shutdown_date_end')}
              onChange={(event): void =>
                handleChange(
                  'shutdown_date_end',
                  event.target.value,
                  shutdownSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>

        <DrawerTitle text="Reprodução" />

        <DrawerRow>
          <DrawerCol>
            <FilterForm.InputText
              label="Desmamaram entre"
              name="weaning_date_init"
              htmlType="date"
              disabled={handleDisabled('weaning_date_init')}
              onChange={(event): void =>
                handleChange(
                  'weaning_date_init',
                  event.target.value,
                  reproductionSection
                )
              }
            />

            <FilterForm.InputText
              emptyLabelSpacing
              name="weaning_date_end"
              htmlType="date"
              disabled={handleDisabled('weaning_date_end')}
              onChange={(event): void =>
                handleChange(
                  'weaning_date_end',
                  event.target.value,
                  reproductionSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <FilterForm.CheckboxButton
              emptyLabelSpacing
              name="aptitude"
              options={REPRODUCTIVE_STATUS_FILTER}
              disabled={handleDisabled('aptitude')}
              onChange={(event): void =>
                handleChange(
                  'aptitude',
                  event.target.checked ? 'aptitude' : '',
                  reproductionSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>
        <DrawerButtonContainer>
          <Button
            type={ButtonType.primary}
            label="Aplicar"
            size={ButtonSize.large}
          />
        </DrawerButtonContainer>
      </FilterForm>
    </CustomDrawer>
  )
}

const AnimalListFilterDrawer: React.FC<AnimalListDrawerProps> = ({
  isOpen,
  onToggle,
  onReset,
  onSubmit,
  farms,
  sectors,
}) => {
  const { handleDisabled, handleChange, resetActiveFilterSection } =
    useSectionDrawer()

  const filterSections: FilterSections = {
    generalInfoSection: [
      'categories',
      'farm_id',
      'sector_id',
      'sex',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
      'is_active',
      'belongs_to_herd',
      'association_animal',
    ],
    birthSection: [
      'last_birth_date_init',
      'last_birth_date_end',
      'birth_forecast_start',
      'birth_forecast_end',
      'last_birth_type',
    ],
    shutdownSection: [
      'shutdown_reason',
      'shutdown_date_init',
      'shutdown_date_end',
    ],
    reproductionSection: [
      'weaning_date_init',
      'weaning_date_end',
      'aptitude',
      'association_animal',
    ],
  }

  const handleFormSubmit = useCallback(
    (
      data: Record<string, unknown>,
      event: React.BaseSyntheticEvent | undefined
    ): Promise<void> => {
      const newData = {
        ...data,
      }

      return onSubmit(newData, event)
    },
    [onSubmit]
  )

  const { Form, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleReset = useCallback((): void => {
    reset()
    onReset?.call(this)
    resetActiveFilterSection()
  }, [onReset, reset, resetActiveFilterSection])

  return (
    <FilterDrawer
      isDrawerOpen={isOpen}
      onClose={onToggle}
      onReset={handleReset}
      form={Form}
      handleDisabled={handleDisabled}
      handleChange={handleChange}
      filterSections={filterSections}
      farms={farms ?? []}
      sectors={sectors ?? []}
    />
  )
}

export { AnimalListFilterDrawer }
