import { useCallback, useEffect, useState } from 'react'

import {
  getReadAllAssociations,
  getReadAssociation,
  postCreateAssociation,
} from 'app/core/services/system'
import { AssociationHook, AssociationHookProps } from 'app/core/types/hooks'
import {
  AssociationCreateRequestData,
  AssociationDetailsResponseData,
  AssociationProps,
} from 'app/core/types/system'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useAssociation = ({
  associationId,
}: AssociationHookProps): AssociationHook => {
  const [associations, setAssociations] = useState<AssociationProps[]>()
  const [association, setAssociation] =
    useState<AssociationDetailsResponseData>()

  const associationsDroplist = (associations ?? []).map(e => ({
    label: e.association_name,
    value: `${e.id}`,
  }))

  const associationFeedingGroupsDroplist = (associations ?? [])
    .flatMap(e => e.feeding_group ?? [])
    .map(e => ({
      label: e.name,
      value: `${e.id}`,
    }))

  const associationHandlingGroupsDroplist = (associations ?? [])
    .flatMap(e => e.handling_group ?? [])
    .map(e => ({
      label: e.name,
      value: `${e.id}`,
    }))

  const readAllAssociations = useCallback(async (): Promise<void> => {
    try {
      const data = await getReadAllAssociations()

      setAssociations(data)
    } catch (e) {
      handleHttpError(e)
    }
  }, [])

  const readAssociation = useCallback(async (): Promise<void> => {
    if (associationId) {
      try {
        const data = await getReadAssociation(associationId)
        setAssociation(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [associationId])

  const addAssociation = useCallback(
    async (request: AssociationCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await postCreateAssociation(request)
          addToast({
            message: Messages.SYSTEM_ASSOCIATION_CREATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    []
  )

  useEffect(() => {
    readAllAssociations()
    readAssociation()
  }, [readAllAssociations, readAssociation])

  return {
    associations,
    association,
    associationsDroplist,
    associationFeedingGroupsDroplist,
    associationHandlingGroupsDroplist,
    addAssociation,
  }
}

export { useAssociation }
