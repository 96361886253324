import { ColumnDef, createColumnHelper, Getter } from '@tanstack/react-table'
import { ReactNode, ReactPortal } from 'react'

import { Chip, ChipColorVariant } from 'components/atoms'

import { AnimalCreateProps, AnimalSexLabel } from 'app/core/types/animal'
import { dateTimeFormat } from 'app/core/utils'

import styles from './styles.module.scss'

const renderDataOrEmpty = (
  getValue: Getter<ReactNode | ReactPortal>
): JSX.Element => {
  const value = getValue()
  return <span>{value || '-'}</span>
}

const renderBooleanChip = (
  getValue: Getter<ReactNode | ReactPortal>
): JSX.Element => {
  const value = getValue()
  return (
    <Chip
      colorVariant={
        value ? ChipColorVariant.defaultTrue : ChipColorVariant.defaultFalse
      }
    />
  )
}

type AnimalColumnsProps = {
  hasAnimalId?: boolean
}

const columnHelper = createColumnHelper<AnimalCreateProps>()

const animalListColumns = ({
  hasAnimalId,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
AnimalColumnsProps): ColumnDef<AnimalCreateProps, any>[] => [
  columnHelper.accessor('id', {
    header: '#',
    cell: (info): JSX.Element => (
      <span className={hasAnimalId ? '' : styles.orderCell}>
        {hasAnimalId ? info.row.original.id : info.row.index + 1}
      </span>
    ),
  }),
  columnHelper.accessor('stock_number', {
    header: 'Nº de Plantel',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
  columnHelper.accessor('electronic_eartag', {
    header: 'Nº de Brinco Eletrônico',
  }),
  columnHelper.accessor('name', {
    header: 'Nome',
  }),
  columnHelper.accessor('birth_date', {
    header: 'Data de Nascimento',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return <span>{value ? dateTimeFormat(value) : '-'}</span>
    },
  }),
  columnHelper.accessor('birth_weight', {
    header: 'Peso de Nascimento',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return <span>{value || '-'} kg</span>
    },
  }),
  columnHelper.accessor('current_weight', {
    header: 'Peso de Entrada',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return <span>{value || '-'} kg</span>
    },
  }),
  columnHelper.accessor('sex', {
    header: 'Sexo',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      if (!value) return <span>-</span>
      return (
        <Chip
          colorVariant={
            value === AnimalSexLabel.female
              ? ChipColorVariant.sexFemale
              : ChipColorVariant.sexMale
          }
        />
      )
    },
  }),
  columnHelper.accessor('category', {
    header: 'Categoria',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      if (!value) return <span>-</span>
      return <Chip label={value} selected={false} />
    },
  }),
  columnHelper.accessor('has_pedigree', {
    header: 'Possui Pedigree',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return (
        <Chip
          colorVariant={
            value ? ChipColorVariant.defaultTrue : ChipColorVariant.defaultFalse
          }
        />
      )
    },
  }),
  columnHelper.accessor('imported_mother', {
    header: 'Mãe',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
  columnHelper.accessor('imported_father', {
    header: 'Pai',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
  columnHelper.accessor('is_composite_breed', {
    header: 'Raça Composta',
    cell: ({ getValue }): JSX.Element => renderBooleanChip(getValue),
  }),
  columnHelper.accessor('breed_first_id', {
    header: 'Raça 1',
    cell: (info): JSX.Element => (
      <Chip label={info.row.original.breed_first_name} selected={false} />
    ),
  }),
  columnHelper.accessor('breed_first_percentage', {
    header: 'Percentual',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return <span>{value ? value + ' %' : '-'}</span>
    },
  }),
  columnHelper.accessor('breed_second_id', {
    header: 'Raça 2',
    cell: (info): JSX.Element => (
      <Chip label={info.row.original.breed_second_name} selected={false} />
    ),
  }),
  columnHelper.accessor('breed_second_percentage', {
    header: 'Percentual',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return <span>{value ? value + ' %' : '-'}</span>
    },
  }),
  columnHelper.accessor('cowhide_color', {
    header: 'Pelagem',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return <Chip label={value} selected={false} />
    },
  }),
  columnHelper.accessor('belongs_to_herd', {
    header: 'Animal de Rebanho',
    cell: ({ getValue }): JSX.Element => renderBooleanChip(getValue),
  }),
  columnHelper.accessor('belongs_to_association', {
    header: 'Animal de Associação',
    cell: ({ getValue }): JSX.Element => renderBooleanChip(getValue),
  }),
  columnHelper.accessor('series', {
    header: 'Série',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
  columnHelper.accessor('breed_association_name', {
    header: 'Associação de Raça',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
  columnHelper.accessor('handling_group', {
    header: 'Grupo de Manejo',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
  columnHelper.accessor('food_group', {
    header: 'Regime Alimentar',
    cell: ({ getValue }): JSX.Element => renderDataOrEmpty(getValue),
  }),
]

export { animalListColumns }
