import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAssociation } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalCreateProps } from 'app/core/types/animal'
import { StorageKeys } from 'app/core/types/storage'
import { SectorProps } from 'app/core/types/system'
import { addToast, getPlural } from 'app/core/utils'
import { Messages } from 'config/messages'

import { animalListColumns } from '../tables'
import { AnimalFeedbackTemplate } from './templates'

const AnimalFeedback: React.FC = () => {
  const history = useHistory()
  const {
    associationsDroplist,
    associationFeedingGroupsDroplist,
    associationHandlingGroupsDroplist,
  } = useAssociation({})

  const navigateTo = (): void => history.push(NavigateList.animalList)

  const feedbackAnimals: AnimalCreateProps[] = JSON.parse(
    localStorage.getItem(StorageKeys.animals_last_review) as string
  )
  const savedSector = JSON.parse(
    localStorage.getItem(StorageKeys.animals_sector_last_review) as string
  ) as SectorProps
  const savedDate = localStorage.getItem(StorageKeys.animals_date_last_review)

  const message = `${getPlural(
    'animal',
    feedbackAnimals.length,
    true
  )} ${getPlural('cadastrado', feedbackAnimals.length)} ${
    Messages.ANIMAL_CREATE_SUCCESS
  }`

  useEffect(() => {
    addToast({ message, type: 'success' })
    localStorage.removeItem(StorageKeys.animals_feedback_positive)
  }, [message])

  const table = useReactTable({
    data: feedbackAnimals.map(animal => ({
      ...animal,
      breed_association_name: associationsDroplist?.find(
        option => option.value === animal.breed_association_id?.toString()
      )?.label,
      handling_group: associationHandlingGroupsDroplist?.find(
        option => option.value === animal.handling_group_id?.toString()
      )?.label,
      food_group: associationFeedingGroupsDroplist?.find(
        option => option.value === animal.feeding_group_id?.toString()
      )?.label,
    })),
    columns: animalListColumns({ hasAnimalId: true }),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!savedDate || !savedSector || !feedbackAnimals) {
    navigateTo()
    return null
  }

  return (
    <AnimalFeedbackTemplate
      date={savedDate}
      sector={savedSector.name}
      animals={feedbackAnimals}
      table={table}
      handleFinish={navigateTo}
    />
  )
}

export { AnimalFeedback }
