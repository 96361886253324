import { useRef } from 'react'
import Switch from 'react-switch'

import { Typography, TypographyVariant } from 'components/atoms'
import { LabeledCard } from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { BRAZILIAN_STATES } from 'app/core/types/system'

import styles from './style.module.scss'

type FarmCreateTemplateProps = {
  form: FormType
  onZipCodeChange: (zipCode: string) => void
  onZipCodeBlur: (zipCode: string) => void
  onCnpjChange: (zipCode: string) => void
  farmStatus: boolean
  onFarmStatusChange: (statues: boolean) => void
  isEdit: boolean
}

const FarmCreateTemplate: React.FC<FarmCreateTemplateProps> = ({
  form: Form,
  onZipCodeChange,
  onZipCodeBlur,
  onCnpjChange,
  farmStatus,
  onFarmStatusChange,
  isEdit,
}) => {
  const buttonFormSubmitRef = useRef<HTMLButtonElement>(null)
  const title = isEdit ? 'Editar fazenda' : 'Cadastrar fazenda'

  return (
    <Form>
      <BoxedLayout
        title={title}
        backButton
        headerLargeButtonLabel="salvar"
        headerLargeButtonAction={(): void =>
          buttonFormSubmitRef.current?.click()
        }
      >
        {
          <div>
            <LabeledCard title="Informações da Fazenda">
              <div className={styles.row}>
                <Form.InputText
                  label="Nome da Fazenda"
                  name="name"
                  placeholder="Inserir nome"
                  labeledInputClassName={styles.rowItemCol2}
                />
                <Form.InputText
                  label="CEP"
                  name="zip_code"
                  placeholder="Inserir CEP"
                  labeledInputClassName={styles.rowItem}
                  onChange={(e): void => {
                    onZipCodeChange(e.currentTarget.value)
                  }}
                  onBlur={(e): void => {
                    onZipCodeBlur(e.currentTarget.value)
                  }}
                />
              </div>
              <div className={styles.row}>
                <Form.InputText
                  label="Endereço"
                  name="address"
                  placeholder="Endereço"
                  labeledInputClassName={styles.rowItem}
                />
                <Form.InputText
                  label="Número"
                  name="address_number"
                  placeholder="Inserir número"
                  labeledInputClassName={styles.rowItem}
                />
                <Form.InputText
                  label="Bairro"
                  name="neighborhood"
                  placeholder="Inserir bairro"
                  labeledInputClassName={styles.rowItem}
                  readOnly
                />
              </div>
              <div className={styles.row}>
                <Form.InputText
                  label="Complemento (opcional)"
                  name="complement"
                  placeholder="Inserir complemento"
                />
                <Form.InputText
                  label="Cidade"
                  name="city"
                  placeholder="Inserir cidade"
                  readOnly
                />
                <Form.Select
                  options={BRAZILIAN_STATES}
                  label="Estado"
                  name="state"
                  placeholder="Selecionar estado"
                  disabled
                />
              </div>
            </LabeledCard>
            <LabeledCard title="Informações administrativas">
              <div className={styles.row}>
                <Form.InputText
                  label="CNPJ"
                  name="cnpj"
                  placeholder="Inserir CNPJ"
                  labeledInputClassName={styles.fieldSmall}
                  onChange={(e): void => {
                    onCnpjChange(e.currentTarget.value)
                  }}
                />
                <Form.InputText
                  label="Inscrição Estadual"
                  name="state_registration"
                  placeholder="Inserir inscrição estadual"
                  labeledInputClassName={styles.fieldSmall}
                />
              </div>
              <div className={styles.row}>
                <Form.InputText
                  label="Área total"
                  name="total_area"
                  placeholder="inserir área total"
                  labeledInputClassName={styles.field}
                  appendHelperText="ha"
                />
                <Form.InputText
                  label="Área produtiva"
                  name="productive_area"
                  placeholder="Inserir área produtiva"
                  labeledInputClassName={styles.field}
                  appendHelperText="ha"
                />
                <div className={styles.switch}>
                  <Typography
                    variant={TypographyVariant.caption3}
                    text={'Status'}
                    className={styles.switchLabel}
                  />
                  <div className={styles.switchRow}>
                    <Switch
                      checked={!!farmStatus}
                      onChange={(status: boolean): void => {
                        onFarmStatusChange(status)
                      }}
                      onColor="#C6DEC5"
                      offColor="#C2C2C2"
                      onHandleColor="#6A9068"
                      offHandleColor="#606062"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                    <Typography
                      variant={TypographyVariant.label}
                      text={farmStatus ? 'Ativo' : 'Inativo'}
                    />
                  </div>
                </div>
              </div>
            </LabeledCard>
          </div>
        }
      </BoxedLayout>
    </Form>
  )
}

export { FarmCreateTemplate }
