/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable no-console */
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { IconNames, SelectItemProps } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import {
  useAssociation,
  useBreed,
  useGetImportAnimalTemplate,
  useImportAnimal,
} from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalCreateProps } from 'app/core/types/animal'
import { StorageKeys } from 'app/core/types/storage'
import { SectorProps } from 'app/core/types/system'
import { addToast, animalsEmptyFields, validateSeries } from 'app/core/utils'
import { Messages } from 'config/messages'

import { animalCreateColumns, animalEditableColumns } from '../tables'
import { createDefaultEmptyRow } from './consts'
import { AnimalCreateTemplate } from './template'

const AnimalCreate: React.FC = () => {
  const history = useHistory<NavigateList>()

  const { breeds, breedsDroplist } = useBreed()
  const {
    associationsDroplist,
    associationFeedingGroupsDroplist,
    associationHandlingGroupsDroplist,
  } = useAssociation({})

  const savedSector = JSON.parse(
    localStorage.getItem(StorageKeys.animals_sector_review) as string
  ) as SectorProps
  const allAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.animals_review) as string
  ) as AnimalCreateProps[]
  const savedAnimals = allAnimals?.some(e => e.error_message)
    ? allAnimals.filter(e => e.error_message)
    : allAnimals

  if (!savedSector) {
    history.push(NavigateList.animalList)
  }

  const [animals, setAnimals] = useState<AnimalCreateProps[]>(
    () => savedAnimals || createDefaultEmptyRow(savedSector.id)
  )

  const handleAddRow = (): void => {
    setAnimals(prevAnimals => [
      ...prevAnimals,
      ...createDefaultEmptyRow(savedSector.id),
    ])
  }

  const handleGoToReview = (): void => {
    let requiredFields = [
      'stock_number',
      'birth_date',
      'birth_weight',
      'current_weight',
      'sex',
    ]

    if (animals.some(e => e.belongs_to_association)) {
      requiredFields = [...requiredFields, 'series']
    }

    const animalErrors = animalsEmptyFields(animals, requiredFields)

    animals?.forEach(animal => {
      setAnimals(prevRows => {
        return prevRows.map(row => {
          if (row.id === animal.id) {
            let seriesError = ''

            if (row.series) {
              const { errorMessage } = validateSeries(row.series)
              if (errorMessage) seriesError = 'SÉRIE: ' + errorMessage
            }

            return {
              ...row,
              error_message: seriesError,
            }
          }
          return row
        })
      })
    })

    if (animalErrors.length) {
      animalErrors?.forEach(invalidAnimal => {
        setAnimals(prevRows => {
          return prevRows.map(row => {
            if (row.id === invalidAnimal) {
              return {
                ...row,
                error_message: Messages.REQUIRED_FIELDS,
              }
            }
            return row
          })
        })
      })

      addToast({ message: Messages.REQUIRED_FIELDS })
      return
    }

    if (!animals.length) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    const cleanErrorMessages = animals.map(e => {
      delete e.error_message
      return e
    })

    localStorage.setItem(
      StorageKeys.animals_review,
      JSON.stringify(cleanErrorMessages)
    )

    history.push(NavigateList.animalReview)
  }

  const defaultColumn = useMemo<Partial<ColumnDef<AnimalCreateProps>>>(
    () =>
      animalEditableColumns(
        breedsDroplist || [],
        associationsDroplist || [],
        associationFeedingGroupsDroplist || [],
        associationHandlingGroupsDroplist || []
      ),
    [
      breedsDroplist,
      associationsDroplist,
      associationFeedingGroupsDroplist,
      associationHandlingGroupsDroplist,
    ]
  )

  const columns = useMemo<ColumnDef<AnimalCreateProps>[]>(
    () => animalCreateColumns({ animals, setAnimals }),
    [animals]
  )

  const table = useReactTable({
    data: animals || [],
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setAnimals(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  useEffect(() => {
    localStorage.setItem(StorageKeys.animals_review, JSON.stringify(animals))
  }, [animals])

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const importAnimal = useImportAnimal
  const getImportAnimalTemplate = useGetImportAnimalTemplate

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    setAnimals([])

    const animals = await importAnimal({
      sectorId: savedSector.id,
      file: selectedFile,
      breeds: breeds ?? [],
    })

    if (animals) {
      const isDroplistEmpty = (droplist: SelectItemProps[] | undefined) =>
        !droplist || !droplist.length

      const updatedAnimals = animals.map(animal => {
        if (animal.belongs_to_association) {
          const breed_association_id = !isDroplistEmpty(breedsDroplist)
            ? Number(breedsDroplist?.[0]?.value)
            : undefined

          const handling_group_id = !isDroplistEmpty(
            associationHandlingGroupsDroplist
          )
            ? Number(associationHandlingGroupsDroplist?.[0]?.value)
            : undefined

          const feeding_group_id = !isDroplistEmpty(
            associationFeedingGroupsDroplist
          )
            ? Number(associationFeedingGroupsDroplist?.[0]?.value)
            : undefined

          return {
            ...animal,
            breed_association_id,
            handling_group_id,
            feeding_group_id,
          }
        }

        return animal
      })

      setAnimals(updatedAnimals)
    }
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        getImportAnimalTemplate()
      },
    },
  ]

  return (
    <AnimalCreateTemplate
      handleAddRow={handleAddRow}
      handleGoToReview={handleGoToReview}
      sector={savedSector.name}
      animals={animals}
      table={table}
      headerSelectButtonItems={selectButtonItems}
    />
  )
}

export { AnimalCreate }
