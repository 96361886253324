import React from 'react'

import {
  Chip,
  ChipColorVariant,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { GenealogyTree } from 'components/organisms'

import {
  AnimalSexLabel,
  IAnimalAdditionalInformation,
  IAnimalAssociation,
  IAnimalBreed,
  IAnimalGenealogy,
  IAnimalReproduction,
} from 'app/core/types/animal'
import { dateTimeFormat } from 'app/core/utils'

import { CHIP_COW_STATUS } from '../../consts'
import {
  BreedCol,
  Col,
  ColGroup,
  InfoCard,
  InfoCardSubject,
  Row,
  RowSingleItem,
} from '../infocard'
import styles from './styles.module.scss'

interface IAnimalAdvancedInfoProps {
  sex: string
  additionalInformation: IAnimalAdditionalInformation
  breed: IAnimalBreed[]
  genealogy: IAnimalGenealogy
  reproduction: IAnimalReproduction
  association: IAnimalAssociation
}

const AnimalAdvancedInfo: React.FC<IAnimalAdvancedInfoProps> = ({
  sex,
  additionalInformation,
  breed,
  genealogy,
  reproduction,
  association,
}) => (
  <div className={styles.advancedInfo}>
    <InfoCard title="Genealogia" collapsible fullWidth>
      <GenealogyTree genealogy={genealogy} />
    </InfoCard>

    <InfoCard title="Informações adicionais">
      <Row>
        <Col>
          <Typography
            text="Animal de rebanho"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Chip
            colorVariant={
              additionalInformation.belongs_to_herd
                ? ChipColorVariant.herdTrue
                : ChipColorVariant.herdFalse
            }
          />
        </Col>

        <Col>
          <Typography
            text="Peso no nascimento"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={
              additionalInformation.birth_weight
                ? `${additionalInformation.birth_weight} kg`
                : '-'
            }
            variant={TypographyVariant.p}
          />
        </Col>
      </Row>

      <RowSingleItem>
        <Typography
          text="Percentual de raça"
          variant={TypographyVariant.caption3}
          altColor
        />
        {additionalInformation.composite_breed && (
          <Chip colorVariant={ChipColorVariant.breedComposite} />
        )}
        {!additionalInformation.composite_breed && breed.length && (
          <Chip
            colorVariant={
              breed.length > 1
                ? ChipColorVariant.breedMixed
                : ChipColorVariant.breedPurebred
            }
          />
        )}
      </RowSingleItem>

      <RowSingleItem>
        {breed.map((item, i) => (
          <BreedCol>
            <Col>
              <Typography
                text={`Raça ${i + 1}`}
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={item.description}
                variant={TypographyVariant.p}
              />
            </Col>

            <Col>
              <Typography
                text="Percentual"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={`${item.percentage}%`}
                variant={TypographyVariant.h5}
              />
            </Col>
          </BreedCol>
        ))}
      </RowSingleItem>
    </InfoCard>

    <InfoCard title="Reprodução">
      <Row>
        <Col>
          <Typography
            text="Aptidão"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Chip
            colorVariant={
              reproduction?.aptitude
                ? ChipColorVariant.suitableTrue
                : ChipColorVariant.suitableFalse
            }
          />
        </Col>
        <Col>
          <Typography
            text="Permite cria"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Chip
            colorVariant={
              reproduction?.allows_breeding
                ? ChipColorVariant.offspringTrue
                : ChipColorVariant.offspringFalse
            }
          />
        </Col>
      </Row>

      {sex === AnimalSexLabel.female && (
        <Row>
          <ColGroup>
            <Col>
              <InfoCardSubject text="Informações reprodutivas" />
              <Typography
                text="Status"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip colorVariant={CHIP_COW_STATUS[reproduction?.status]} />
            </Col>
          </ColGroup>
        </Row>
      )}

      {sex === AnimalSexLabel.female && reproduction?.expected_birth_date && (
        <Row>
          <ColGroup justifyEnd>
            <Col>
              <InfoCardSubject text="" />
              <Typography
                text="Previsão do parto"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={dateTimeFormat(reproduction.expected_birth_date)}
                variant={TypographyVariant.p}
              />
            </Col>
          </ColGroup>
        </Row>
      )}
    </InfoCard>

    <InfoCard title="Associação" fullWidth>
      <Row>
        <ColGroup>
          <Col>
            <Typography
              text="Animal de associação"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Chip
              colorVariant={
                association
                  ? ChipColorVariant.associationTrue
                  : ChipColorVariant.associationFalse
              }
            />
          </Col>

          {association && (
            <Col>
              <Typography
                text="Grupo de manejo"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={association.handling_group_name}
                variant={TypographyVariant.p}
              />
            </Col>
          )}
        </ColGroup>

        {association && (
          <ColGroup>
            <Col>
              <Typography
                text="Associação de raça"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                className={styles.associationText}
                text={association.breed_association_name}
                variant={TypographyVariant.p}
              />
            </Col>

            <Col>
              <Typography
                text="Regime alimentar"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={association.feeding_group_name}
                variant={TypographyVariant.p}
              />
            </Col>
          </ColGroup>
        )}
      </Row>
    </InfoCard>
  </div>
)

export { AnimalAdvancedInfo }
