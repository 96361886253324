import { ColumnDef } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames, Tooltip } from 'components/atoms'
import { handleDeleteRow } from 'components/organisms/custom-table/helpers'

import { AnimalCreateProps } from 'app/core/types/animal'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type AnimalColumnsProps = {
  animals: AnimalCreateProps[]
  setAnimals: Dispatch<SetStateAction<AnimalCreateProps[]>>
}

const animalCreateColumns = ({
  animals,
  setAnimals,
}: AnimalColumnsProps): ColumnDef<AnimalCreateProps, unknown>[] => [
  {
    header: '#',
    cell: (info): JSX.Element => (
      <span className={styles.orderCell}>{info.row.index + 1}</span>
    ),
  },
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
  },
  {
    header: 'Nome',
    accessorKey: 'name',
  },
  {
    header: 'Data de Nascimento',
    accessorKey: 'birth_date',
  },
  {
    header: 'Peso de Nascimento',
    accessorKey: 'birth_weight',
  },
  {
    header: 'Peso de Entrada',
    accessorKey: 'current_weight',
  },
  {
    header: 'Sexo',
    accessorKey: 'sex',
  },
  {
    header: 'Categoria',
    accessorKey: 'category',
  },
  {
    header: 'Possui Pedigree',
    accessorKey: 'has_pedigree',
  },
  {
    header: 'Mãe',
    accessorKey: 'imported_mother',
  },
  {
    header: 'Pai',
    accessorKey: 'imported_father',
  },
  {
    header: (): JSX.Element => (
      <Tooltip
        label="Raça composta"
        tooltip={Messages.ANIMAL_COMPOSITE_BREED_TOOLTIP}
      />
    ),
    accessorKey: 'is_composite_breed',
  },
  {
    header: 'Raça 1',
    accessorKey: 'breed_first_id',
  },
  {
    header: (): JSX.Element => (
      <Tooltip label="Percentual" tooltip={Messages.ANIMAL_BREED_TOOLTIP} />
    ),
    accessorKey: 'breed_first_percentage',
  },
  {
    header: 'Raça 2',
    accessorKey: 'breed_second_id',
  },
  {
    header: 'Percentual',
    accessorKey: 'breed_second_percentage',
    cell: (info): JSX.Element => {
      const percentage = info.row.original.breed_second_percentage
      return (
        <span>
          {percentage && percentage !== '0' ? percentage + ' %' : '-'}
        </span>
      )
    },
  },
  {
    header: 'Pelagem',
    accessorKey: 'cowhide_color',
  },
  {
    header: 'Animal de Rebanho',
    accessorKey: 'belongs_to_herd',
  },
  {
    header: 'Animal de Associação',
    accessorKey: 'belongs_to_association',
  },
  {
    header: 'Série',
    accessorKey: 'series',
  },
  {
    header: 'Associação de Raça',
    accessorKey: 'breed_association_id',
  },
  {
    header: 'Grupo de Manejo',
    accessorKey: 'handling_group_id',
  },
  {
    header: 'Regime Alimentar',
    accessorKey: 'feeding_group_id',
  },
  {
    id: 'delete',
    cell: (info): JSX.Element => (
      <Icon
        name={IconNames.close}
        onClick={(): void =>
          handleDeleteRow(animals, setAnimals, info.row.index)
        }
      />
    ),
  },
]

export { animalCreateColumns }
